<template>
  <div style="height: inherit" class="w-100">
    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-9 col-sm-12"
          >
            <h3 class="mb-25 font-weight-bolder">Notifications</h3>
            <p class="mb-50">Manage all your notifications.</p>
          </div>
          <div class="col-md-3 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <b-button
                size="sm"
                variant="success"
                @click="handleMarkAllNotificationAsRead"
                ><feather-icon icon="CheckCircleIcon" class="mr-50" /><span
                  class="align-middle"
                  >Mark all as read</span
                ></b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Search Notification..."
              debounce="500"
              size="md"
              v-model="filters.message"
            />
          </b-input-group>
        </div>
        <div
          class="col-md-4 col-sm-12 d-flex align-items-center justify-content-end"
        >
          <b-form-checkbox
            v-model="filters.mark_as_seened"
            :value="false"
            :unchecked-value="null"
            class="custom-control-success"
          >
            Only Unread Notifications
          </b-form-checkbox>
        </div>
      </div>
      <!-- {{index}} -->
      <div class="position-relative table-responsive">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col" class="text-center">
                  SL No:
                </th>
                <th role="columnheader" scope="col">Notification</th>
                <th role="columnheader" scope="col">Created</th>
                <th role="columnheader" scope="col">Type</th>
                <th role="columnheader" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr
                @click="handleNotificationClick(notification)"
                role="row"
                v-for="(notification, index) in notifications"
                :key="notification.notification_id"
              >
                <th scope="row" class="text-center mx-1" style="width: 120px">
                  {{ index + pagination.from }}
                </th>

                <td role="cell">
                  <div
                    class="d-flex flex-row align-items-center justify-content-start"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center mr-1"
                    >
                      <div
                        class="d-flex align-items-center justify-content-center border"
                        style="width: 49px; height: 49px; border-radius: 50%"
                      >
                        <feather-icon
                          size="20"
                          class="text-dark"
                          :icon="
                            getIconFromNotificationType(
                              notification.notification_type
                            )
                          "
                        />
                      </div>
                    </div>

                    <div
                      class="w-100 d-flex flex-column align-items-start justify-content-center"
                    >
                      <div
                        class="w-100 d-flex flex-row align-items-center justify-content-between mb-50"
                      >
                        <div
                          class="d-flex flex-row align-items-center justify-content-start"
                        >
                          <b-badge
                            v-if="notification.mark_as_seened != true"
                            variant="success"
                            >New</b-badge
                          >
                        </div>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <p class="mb-0 text-dark font-weight-bold">
                          {{ notification.message }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td role="cell">
                  <p class="mb-0" style="min-width: 140px">
                    {{ notification.created_at | formatdate }}
                  </p>
                </td>
                <td role="cell">
                  <b-badge variant="primary">{{
                    getTextFromNotificationType(notification.notification_type)
                  }}</b-badge>
                </td>
                <td role="cell">
                  <b-button
                    size="sm"
                    variant="flat-danger"
                    @click.stop="
                      handleDeleteNotificationClick(
                        notification.notification_id
                      )
                    "
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </td>
              </tr>
              <template
                v-if="
                  notifications.length == 0 && filters.mark_as_seened == false
                "
              >
                <tr>
                  <td colspan="12">
                    <div
                      class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
                    >
                      <feather-icon icon="MailIcon" size="34" class="mb-50" />
                      <h3 class="font-weight-bolder">No Notifications</h3>
                      <p>
                        Uncheck
                        <span
                          class="font-weight-bold text-primary"
                          @click="
                            () => {
                              filters.mark_as_seened = true;
                            }
                          "
                          >Only Unread Notifications</span
                        >
                        to view all notifications.
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-overlay>
      </div>
      <div class="card-body">
        <div
          class="demo-spacing-0 d-flex align-items-center justify-content-center"
        >
          <!-- <p class="align-middle mb-0">
            Showing {{ pagination.from }} to {{ pagination.to }} of
            {{ pagination.total }} entries
          </p> -->

          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  BListGroup,
  BListGroupItem,
  BButton,
  BOverlay,
  BPagination,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import NotificationMixins from "../../mixins/NotificationMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import moment from "moment";
export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BLink,
    BBadge,
    BButton,
    BOverlay,
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
    BPagination,
  },

  mixins: [NotificationMixins, ResponseMixins],

  data() {
    return {
      filters: {
        message: null,
        mark_as_seened: false,
        page: 1,
      },
      pagination: {
        total: 1,
        to: 1,
        from: 1,
        perPage: 10,
      },
      showOverlay: false,
    };
  },

  watch: {
    filters: {
      handler: function (newValue) {
        this.showOverlay = true;
        this.$store
          .dispatch("notification/getNotifications", newValue)
          .then((res) => {
            this.pagination.total = res.data.count;
            if (res.data.next && res.data.next !== null) {
              this.pagination.to = this.getPageNumberFromUrl(res.data.next);
            } else {
              this.pagination.to = newValue.page;
            }
            if (res.data.previous && res.data.previous !== null) {
              this.pagination.from = this.getPageNumberFromUrl(
                res.data.previous
              );
            } else {
              this.pagination.from = newValue.page;
            }
            console.log("New Pagination", this.pagination);
          })
          .catch((err) => {
            if (
              (err.response && err.response.status === 404) ||
              err.status == 404
            ) {
              if (this.filters.page > 1) {
                this.filters.page = 1;
              }
            }
            console.log(err);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      },
      deep: true,
    },
  },

  created() {
    // let channel = this.$store.state.app.user.id + "_secusy_channel";
    let channel = "tm_" + this.$store.state.app.org_id + "_user_" +this.$store.state.app.user.id + "_channel"; 
    this.sockets.subscribe(channel, (data) => {
      this.$store.dispatch("notification/getNotifications");
    });
  },

  mounted() {
    this.load();
  },

  computed: {
    notifications: {
      get() {
        return this.$store.state.notification.notifications;
      },
    },
  },
  filters: {
    formatdate: function (timestamp) {
      if (timestamp) {
        return moment(String(timestamp)).format("DD-MMM-YY hh:mm A");
      }
    },
  },

  methods: {
    load() {
      this.showOverlay = true;
      this.$store
        .dispatch("notification/getAllNotifications", {})
        .finally(() => {
          this.$store
            .dispatch("notification/getNotifications", this.filters)
            .finally(() => {
              this.showOverlay = false;
            });
        });
    },

    getPageNumberFromUrl(url) {
      let partsArray = url.split("page=");
      if (typeof partsArray[1] == "undefined") {
        return 1;
      }
      return Number(partsArray[1]);
    },

    handleMarkAllNotificationAsRead() {
      this.$store
        .dispatch("notification/markAllNotificationsAsRead")
        .then((res) => {
          this.$store
            .dispatch("notification/getNotifications", this.filters)
            .then(() => {
              this.$store.dispatch("notification/getAllNotifications", {});
            });
        });
    },

    handleNotificationClick(notification) {
      this.$store
        .dispatch(
          "notification/markNotificationAsRead",
          notification.notification_id
        )
        .then((res) => {
          const newRoute = this.getRouteFromNotificationType(notification);
          this.$router.push(newRoute);
          this.$store
            .dispatch("notification/getNotifications", this.filters)
            .then(() => {
              this.$store.dispatch("notification/getAllNotifications", {});
            });
        })
        .catch((err) => {
          // this.handleError(err);
          console.log(err);
        });
    },

    handleDeleteNotificationClick(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete this Notification.",
          {
            title: "Warning",
            size: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value) {
            this.deleteNotificationAndSetData(id);
          }
        });
    },

    deleteNotificationAndSetData(id) {
      this.$store
        .dispatch("notification/deleteNotification", id)
        .then((res) => {
          this.$store
            .dispatch("notification/getNotifications", this.filters)
            .then(() => {
              this.$store.dispatch("notification/getAllNotifications", {});
            });
        });
    },
    getIconFromNotificationType(type) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (type) {
        case `REPORT`:
          return "BookIcon";
        case "TASK":
          return "EditIcon";
        case "SCAN":
          return "SearchIcon";
        case "VULN":
          return "AlertTriangleIcon";
        case "BASELINE":
          return "SearchIcon";
        case "DOMAINSCAN":
          return "SearchIcon";
        default:
          return "BookIcon";
      }
    },
    getTextFromNotificationType(type) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (type) {
        case `REPORT`:
          return "Report";
        case "TASK":
          return "Task";
        case "SCAN":
          return "Scan";
        case "VULN":
          return "Vulnerabilities";
        case "BASELINE":
          return "Asset Overview";
        case "DOMAINSCAN":
          return "Domain Scan";
        default:
          return "Unknown";
      }
    },
    formatTimeStampToDate(timestamp) {
      return `${this.getMonthTextFromDate(timestamp)} ${new Date(
        timestamp
      ).getDate()}, ${new Date(timestamp).getFullYear()} ${new Date(
        timestamp
      ).getHours()}:${new Date(timestamp).getMinutes()}:${new Date(
        timestamp
      ).getSeconds()}`;
    },

    getMonthTextFromDate(data) {
      const num = new Date(data).getMonth();
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = months[num];
      return month;
    },
  },
};
</script>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }
  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
