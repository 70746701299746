export default {
  methods: {
    getAllNotifications(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}notification/notification`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getNotification(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}notification/notification/${id}/`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    markNotificationAsRead(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "PATCH",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}notification/notification/${id}/mark-as-read/`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    deleteNotification(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}notification/notification/${id}/`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    markAllNotificationsAsRead() {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}notification/notification/mark-all-as-read/`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getRouteFromNotificationType(notification) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (notification.notification_type) {
        case "REPORT":
          return "/reports/";
        case "TASK":
          return {
            path: "/task",
            query: { taskId: notification.reference_id },
          };
        case "SCAN":
          return `/assets/scan/${notification.reference_id}`;
        // return "/scans/";
        case "VULN":
          return `/vulnerability/detail/${notification.reference_id}`;
        // return "/assets/vulnerabilities";
        case "BASELINE":
          return `/assets/scan/${notification.reference_id}`;
        case "DOMAINSCAN":
          return "/Discovery?tabIndex=1";
        case "ASSET":
          return `/assets/scan/${notification.reference_id}`;
        default:
          return "BookIcon";
      }
    },
  },
};
